import request from '@/utils/requestV2'
const qs = require('qs')

// 条件查询储值合同列表，分页显示
export function getContractPage (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/getContractPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 产品剩余库存量汇总
export function countAvailableProductQuantity (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/countAvailableProductQuantity',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 服务项剩余库存量汇总
export function countAvailableServiceItemQuantity (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/countAvailableServiceItemQuantity',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 储值合同创建接口
export function createStoredContract (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/createStoredContract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看消费明细记录的详情信息
export function getInvoice (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/getContractRecordDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 条件查询储值和消耗记录列表明细，分页显示
export function getContractRecordPage (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/getContractRecordPage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取合同状态列表
export function getStatusList (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/getStatusList',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看储值合同的详情信息
export function getStoredContractDetail (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/getStoredContractDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 作废合同
export function invalidContract (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/invalidContract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询当前交易结算单是否可使用储值库存进行抵扣
export function judgeUseStoredContract (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/judgeUseStoredContract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询储值合同明细包含的资源列表
export function listStoredContractResource (data) {
  return request({
    url: '/ooh-scp/v1/storedContract/listStoredContractResource',
    method: 'post',
    data: qs.stringify(data)
  })
}
